<template>
    <div class="">
        <el-table
            :data="tableData"
            size="medium"
            :header-cell-style="{ fontWeight: 'normal', color: '#666', background: '#F6F6F6', height: '60px' }"
            class="customTable"
            :cell-style="{ fontSize: '16px', color: '#333', height: '70px' }"
            :row-key="rowKey"
        >
            <el-table-column
                v-for="(item, index) in excludeOperate"
                :key="index"
                :width="item.width"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
            >
                <template slot-scope="scope">
                    <div v-if="item.isImg && scope.row[item.prop]" class="img-box">
                        <img class="img" :src="scope.row[item.prop]" alt="" />
                    </div>
                    <div v-else-if="item.isSwitch" class="switch-box">
                        <el-switch
                            :value="scope.row[item.prop]"
                            active-color="#13ce66"
                            inactive-color="#e8e8e8"
                            @change="toggleSwitch(scope.row)"
                        ></el-switch>
                    </div>
                    <div v-else class="content" v-html="scope.row[item.prop]"></div>
                </template>
            </el-table-column>
            <el-table-column
                v-if="operateConfig"
                :label="operateConfig.label"
                :width="operateConfig.width"
                :align="operateConfig.align"
            >
                <template slot-scope="scope">
                    <slot :item="scope.row"></slot>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: "TableList",
    data() {
        return {
            switchVal: false,
        };
    },
    props: {
        tableData: {
            type: Array,
            default() {
                return [];
            },
        },
        tableColumnConfig: {
            type: Array,
            default() {
                return [];
            },
        },
        toggleSwitch: {
            type: Function,
            default() {
                return function() {};
            },
        },
        rowKey: {
            type: String,
            default: "",
        },
    },
    computed: {
        operateConfig() {
            return this.tableColumnConfig.find((item) => item.label == "操作");
        },
        excludeOperate() {
            return this.tableColumnConfig.filter((item) => item.label != "操作");
        },
    },
    components: {},
    methods: {},
};
</script>

<style scoped lang="scss">
.img-box {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    .img {
        width: 100%;
        height: 100%;
    }
}
.switch-box {
    display: flex;
    align-items: center;
}
::v-deep .el-table .cell div {
    display: inline-block;
}
</style>
